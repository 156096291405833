.navbarMain{ 
    background-color: rgba(255,255,255);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2; 
    height: 70px;   
    transition: 100ms;
    display:flex;
    align-items: center; 
}
.navbarMain--hidden{
    transform: translateY(-70px);
    transition: 1000ms; 
}
.navAllProducts{ 
    display: none;
    top: 0px;
    min-width:200px;
    height:auto;
    position : absolute; 
    color: white;
 
}
.navhover:hover .navAllProducts{
    display: block;
}
.navCats {
    background-color: #27448F;
    position: relative;
    border-top:1px solid  ;
   
}
 
.navCats:hover {
    background-color: #041644;
    color: white; 
}
.navCats:hover .navCatsAll{
   display: block;
}

.navCatsAll{
    min-width:200px ; 
    position: absolute;
    left: 100%;
    top: 0px; 
    display: none;  
}
 .navCatsAllDiv{
    background-color: #27448F; 
    color: white;
 }
 .navCatsAllDiv:hover{
    background-color: #041644;
    color: white;
 }

 
.navPc{
    display: block;
}
.navMobile{
    display: none;
}
.titleText{
    font-size: 180px;
    line-height: 100%; 
}
.imgIffect>div>img { 
    transition: 0.3s linear !important;
} 
.productBox:hover .imgIffect>div>img {
    transform: scale(1.1);
 
}

 

@keyframes breath {
    0% { scale: 1 }
    50% { scale: 1.2 }
    100% { scale: 1 }
  }
.homeTopImg{
    animation: breath 8s linear infinite;
}
.aboutBg{
    background-image: url('../image/contactBg.png');
    background-repeat: no-repeat;
    background-size: 80% 100%;
}
.productDetail{
    display: block !;
}


.button_su{
    overflow: hidden;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    cursor: pointer;  
  }
  
  .su_button_circle{ 
    border-radius: 1000px;
    position: absolute;
    left:0;
    top:0;
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    pointer-events: none; 
  }
  
  .button_su_inner{
      display: flex; 
      font-size: 16px;
      font-weight: normal;
      width: 100%;
      text-align: center;
      border-radius: 3px;
      transition: 400ms;
      text-decoration: none;
     
      z-index: 1;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
  }
  
  .button_text_container{
     position:relative;
     z-index: 1;
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     justify-content: center;
     padding: 5px 20px;
  }
  
  .explode-circle {
     animation: explode 1s forwards; 
  }

  .BgText{
    color: rgba(255, 255, 255, 0.10); 
    font-size: 200px;
    font-style: normal;
    font-weight: 800;
    line-height: 92%; /* 282.44px */
    text-transform: uppercase;
  }
  
   
  @keyframes explode {
      0% {
      width: 0px;
      height: 0px;
      margin-left: 0px;
      margin-top: 0px;
      background-color: #1f3775;
    }
      50% {
      width: 400px;
      height: 400px;
      margin-left: -200px;
      margin-top: -200px;
      background-color:#1f3775;
    }
  
  }
 
  

   
  .react-multiple-carousel__arrow--right{
    right: 0%  !important;
    z-index: 0 !important;
  }
.react-multiple-carousel__arrow--left{
    left: 0% !important;
    z-index: 0 !important;

}

.productList{
    color: black;
}
.productList:hover{
    color: blue;
}

 
  
.mbProductCats{
    display:none;
    text-align: start;  
    margin-top:5px;
    margin-left: 20px; 
    opacity: 1;
}
.mbAllProduct:hover .mbProductCats{
    display:block !important;
    text-align: start;  
    margin-top:5px;
    margin-left: 20px; 
    opacity: 1;
 
}
.mbCatsOption{
    display: none;
    margin-left: 20px;
}
.mbCats:hover .mbCatsOption{
    display: block;
    margin-left: 20px;
}

@media(max-width:1100px){
    .productDetail{
        display: none !important;
    }
}
@media(max-width:990px){
   
 
 
    .navPc{
        display: none;
    }
    .navMobile{
        display: block;
    }
    
    .titleText{
        font-size: 120px;
        line-height: 100%; 
    }
  
   
}
@media(max-width:768px){
 
    .titleText{
        font-size: 90px;
        line-height: 100%; 
    }
    .aboutBg{
        background-image: url('../image/contactBg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 30px;
    }
    .productDetail{
        display: none !important;
    }
}

 

@media(max-width:550px){
 
    .titleText{
        font-size: 55px;
        line-height: 100%; 
    }
}

