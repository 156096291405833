.App {
  text-align: center;
  overflow: hidden;
}
 
.tJustify {
  text-align: justify;
}


.hamburger .line{
  width: 35px;
  height: 4px;
  background-color: #294A9F;
  display: block;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
  transition: 1s;
  
}

.hamburger:hover{
  cursor: pointer;
}

#hamburger{
  position: relative;
  right:20px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

#hamburger.is-active{
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger:before{
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 45px;
  height: 45px;
  border: 3px solid transparent;
  top: calc(80% - 30px);
  left: calc(75% - 30px);
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger.is-active:before{
  border: 4px solid #FFFFFF; 

}

#hamburger.is-active .line{
  width: 25px;
  background-color: #FFFFFF; 
}

#hamburger.is-active .line:nth-child(2){
  opacity: 0;
  background-color: #FFFFFF;

}

#hamburger.is-active .line:nth-child(1){
  -webkit-transform: translateY(9px);
  -ms-transform: translateY(9px);
  -o-transform: translateY(9px);
  transform: translateY(9px);
  background-color: #FFFFFF;

}

#hamburger.is-active .line:nth-child(3){
  -webkit-transform: translateY(-13px) rotate(90deg);
  -ms-transform: translateY(-13px) rotate(90deg);
  -o-transform: translateY(-13px) rotate(90deg);
  transform: translateY(-11px) rotate(90deg);
  background-color: #FFFFFF;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
